<template>
  <div>
    <!-- Main -->
    <vs-card>
      <div class="d-flex w-100 justify-content-between align-items-end mb-3">
        <div>
          <h3>Gestão de contas</h3>

          <p class="mb-0 mt-2">
            Somente aqueles com acesso a esta plataforma podem visualizá-lo.
          </p>
        </div>

        <div>
          <vs-button
            color="primary"
            class="mr-3"
            icon="person_add"
            @click="callCreatePopup"
          >
            Adicionar Conta
          </vs-button>
        </div>
      </div>

      <div
        v-if="ownerId === 0"
        class="mt-2 mb-3 col-sm-12 col-md-12 col"
      >
        <label
          class="mr-2"
          for="access-owner-select"
        >
          <strong>Selecione o Owner:</strong>
        </label>

        <select
          id="access-owner-select"
          v-model="selectedOwner"
          autocomplete="on"
          tabindex="0"
          class="c-input"
          @change="getAccounts"
        >
          <option
            v-for="owner in owners"
            :key="owner.id"
            :value="owner.id"
          >
            {{ `${owner.id} : ${owner.nickName} - ${owner.entityName}` }}
          </option>
        </select>
      </div>

      <vs-table
        id="contact-table"
        stripe
        :data="accounts"
        no-data-text="Nenhuma conta disponível."
        class="text-nowrap border"
      >
        <template slot="thead">
          <vs-th>Login</vs-th>

          <vs-th>Permissões</vs-th>

          <vs-th>Data de Criação</vs-th>

          <vs-th v-if="checkItemAccess('Admin')">
            Ações
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="(account, accIdx) in data"
            :key="accIdx"
          >
            <!-- Login -->
            <vs-td>
              <i class="float-left mdi mdi-email mr-2" />

              <span>
                {{ account.userLogin }}
              </span>
            </vs-td>

            <!-- Account Accesses/Permissions -->
            <vs-td>
              <i class="float-left mdi mdi-account-key mr-2" />

              <div class="d-flex flex-wrap">
                <span
                  v-for="(access, accessIdx) in account.accessValues"
                  :key="accessIdx"
                  class="badge badge-light badge-pill border border-muted mr-1 mb-1"
                >
                  <vs-tooltip
                    :text="access.desc"
                    position="top"
                  >
                    {{ access.tipo }}
                  </vs-tooltip>
                </span>
              </div>
            </vs-td>

            <!-- Creation Date -->
            <vs-td>
              <span>{{
                account.creationDate ? formatDate(account.creationDate) : '-'
              }}</span>
            </vs-td>

            <!-- Actions -->
            <vs-td v-if="checkItemAccess('Admin')">
              <div class="d-flex">
                <!-- Edit Account -->
                <vs-button
                  class="text-dark mr-1"
                  title="Editar conta"
                  type="flat"
                  icon="edit"
                  size="small"
                  @click="callEditPopup(account)"
                />

                <!-- Remove Access -->
                <vs-button
                  class="text-dark"
                  title="Deletar conta"
                  color="danger"
                  type="flat"
                  size="small"
                  icon="delete"
                  @click="callConfirmDialog(account)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>

    <!-- Create Account Prompt -->
    <vs-prompt
      title="Adicionar Conta"
      :active.sync="openCreateAccPopup"
      :buttons-hidden="true"
    >
      <p>
        Abaixo está o link para a criação de novas contas. Encaminhe para a
        pessoa de interesse, e após a realização do cadastro a conta estará
        inclusa na lista para liberação. Lembre-se que o usuário precisa ter a
        permissão "Acesso" para logar no FanBox.
      </p>

      <vs-input
        v-model="registerUrl"
        class="w-100 mb-3"
        readonly
      />
    </vs-prompt>

    <!-- Edit Permissions Prompt -->
    <vs-prompt
      title="Editar Conta"
      :active.sync="openEditAccPopup"
      accept-text="Salvar"
      cancel-text="Cancelar"
      @accept="editAccount"
    >
      <vs-input
        v-model="selectedAccount.userLogin"
        class="w-50 mb-3"
        label="Login:"
        readonly
        disabled
      />

      <label class="pl-1 font-weight-bold block"> Permissões: </label>

      <div
        v-for="(access, accessIdx) in accessOptions"
        :key="accessIdx"
        class="d-flex justify-content-left"
      >
        <vs-checkbox
          v-model="updatedAccessValues"
          :vs-value="access"
          class="mb-2"
        >
          {{ access }}
        </vs-checkbox>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from 'moment';
import System from '@/services/system';

const systemService = new System();

export default {
  name: 'AccessMgmt',
  data: () => ({
    accessValues: [],
    accounts: {},
    owners: [],
    selectedAccount: 0,
    selectedOwner: 0,
    updatedAccessValues: [],
    descAccess: [{
      'type': 'Fanbox',
      'desc': 'Visualização dos dados do Fanbox.'
    },
    {
      'type': 'Filtros',
      'desc': 'Consultas na base com filtros e criação de filtros modelos.'
    },
    {
      'type': 'Meus negócios',
      'desc': 'Visualização dos dados de "Meus Negócios" (Sócio Torcedor, Estádio, App, OTT...).'
    },
    {
      'type': 'Campanhas',
      'desc': 'Visualização das campanhas realizadas e suas informações.'
    },
    {
      'type': 'Surveys',
      'desc': 'Visualização dos dados e estruturas de Censos e Projetos Digitais em "Hub de Operações".'
    },
    {
      'type': 'Acesso',
      'desc': 'Permissão para login.'
    },
    {
      'type': 'Editar Campanhas',
      'desc': 'Edição, criação e promoção de novas campanhas.'
    },
    {
      'type': 'Admin',
      'desc': 'Gestão de Contas e suas permissões.'
    },
    {
      'type': 'Editar Projetos Digitais',
      'desc': 'Alteração da estrutura de Censos e Projetos Digitais em "Hub de Operações".'
    },
    {
      'type': 'LGPD',
      'desc': 'Visualização de dados não anonimizados.'
    },
    {
      'type': 'Events',
      'desc': 'Visualização dos eventos em "Hub de Operações".'
    },
    {
      'type': 'Dev',
      'desc': 'Visualização de códigos e variáveis que ajudam na manutenção e suporte do aplicativo.'
    }],
    openCreateAccPopup: false,
    openEditAccPopup: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    systemId() {
      return this.$store.state.sysID;
    },
    registerUrl() {
      return this.$store.state.registerUrl;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    loginData() {
      return this.$store.state.loginData;
    },
    accessOptions() {
      if (this.accessValues.length > 0) {
        const restrictedValues = ['Admin', 'Dev', 'Editar Projetos Digitais'];
        return this.accessValues.filter(
          (value) => !restrictedValues.includes(value)
        );
      }

      return [
        'Campanhas',
        'Fanbox',
        'Filtros',
        'Meus negócios',
        'Surveys',
        'Votações',
      ];
    },
  },
  created() {
    this.selectedOwner = this.ownerId;
    if (this.ownerId === 0) this.getOwners();
    this.getAccounts();
  },
  methods: {
    /* API */
    getAccounts() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getAccounts(this.selectedOwner, this.systemId, this.token)
        .then(
          (resp) => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.accounts = resp.data.accounts;

            this.accessValues = resp.data.defaultAccessValues;

            for (var i = 0; i < this.accounts.length; i++) {
              var accessValues = this.accounts[i].accessValues;
              var updatedAccessValues = [];

              for (var j = 0; j < accessValues.length; j++) {
                var type = accessValues[j];
                var desc = '';

                for (var k = 0; k < this.descAccess.length; k++) {
                  if (this.descAccess[k].type === type) {
                    desc = this.descAccess[k].desc;
                    break;
                  }
                }

                updatedAccessValues.push({ tipo: type, desc: desc });
              }

              this.accounts[i].accessValues = updatedAccessValues;
            }
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.$vs.notify({
              color: 'danger',
              title: 'Erro ao carregar projeto',
              text: err,
            });
          }
        );
    },
    getOwners() {
      systemService.getOwners().then(
        (resp) => {
          this.owners = resp.data.data;
        },
        (err) => {
          this.$vs.notify({
            color: 'danger',
            title: 'Erro ao carregar projeto',
            text: err,
          });
        }
      );
    },
    editAccount() {
      const payload = {
        ownerId: this.selectedOwner,
        userLogin: this.selectedAccount.userLogin,
        accessValues: this.updatedAccessValues,
      };

      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .editAccount(payload, this.token)
        .then(() => {
          this.getAccounts();
          this.$store.dispatch('set_isLoadingActive', false);
          this.$vs.notify({
            color: 'success',
            title: 'Conta atualizada',
            text: 'Conta atualizada com sucesso!',
          });
          this.clearEditForm();
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.$vs.notify({
            color: 'danger',
            title: 'Erro ao atualizar',
            text: err,
          });
        });
    },
    revokeAccess() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .revokeAccess(
          this.selectedOwner,
          this.systemId,
          this.selectedAccount.userLogin,
          this.token
        )
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.getAccounts();

          this.$vs.notify({
            color: 'success',
            title: 'Conta removida',
            text: 'Conta removida com sucesso!',
          });
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.$vs.notify({
            color: 'danger',
            title: 'Erro ao apagar acesso',
            text: err,
          });
        });
    },
    formatDate(datetime) {
      const date = datetime.split('T')[0].replaceAll('-', '/');
      return moment(date).format('DD/MM/YYYY');
    },
    checkItemAccess(access) {
      if (!access) return true;

      if (this.loginData.accessValues.includes(access)) {
        return true;
      }

      return false;
    },
    clearEditForm() {
      this.updatedAccessValues = [];
    },
    callCreatePopup() {
      this.openCreateAccPopup = true;
    },
    callEditPopup(selectedAccount) {
      let accessValues = selectedAccount.accessValues.map(item => item.tipo);
      this.selectedAccount = selectedAccount;
      this.updatedLogin = selectedAccount.userLogin;
      this.updatedAccessValues = accessValues;
      this.openEditAccPopup = true;
    },
    callConfirmDialog(selectedAccount) {
      this.selectedAccount = selectedAccount;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Deletar conta',
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        text: `Remover a seguinte conta: "${selectedAccount.userLogin}"? `,
        accept: this.revokeAccess,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #2b2b2b;
  cursor: pointer;
  height: 2rem;
  padding: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
  width: 33%;
}
</style>
